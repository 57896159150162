<template>
  <!-- 产品模块 -->
  <article class="module-list core">
    <div class="module-item" v-for="(item, index) in floorGoodList" :key="index">
      <div class="item-title row-between mb20">
        <div class="name bold black">{{ item.name }}</div>
        <div class="link-list md muted row">
          <div class="link-item row muted" v-for="(el, _i) in item.sons[item.select].sons" :key="_i">
            <span class=" cursor " :class="{ primary: _i == item.sons[item.select].select }" @click="item.sons[item.select].select = _i,$forceUpdate()">
              {{ el.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="item-pro row-between">
        <div class="pro-l column-between white" :style="{ backgroundImage: `url(${item.ad})` }">
          <div class="pro-tittle xxl">{{ item.name }}</div>
          <ul class="pro-intro nr">
            <li class="inpro-item row" v-for="(el, _i) in item.sons" :key="_i">
              <span class="cursor" :class="{ primary: _i == item.select }" @click="item.select = _i">{{ el.name
              }}</span>
            </li>
          </ul>
        </div>
        <ul class="pro-r row wrap">
          <li class="pro-item" v-for="(el, _i) in item.sons[item.select].sons[item.sons[item.select].select].goods_lists" :key="_i">
            <router-link :to="`/prodetail?id=${el.id}`">
              <div class="row-center">
                <el-image :src="el.image" fit="contain" lazy class="item-img"></el-image>
              </div>
              <div class="item-text">
                <div class="line1 mb5 sm normal text-title">
                  {{ el.name }}
                </div>
                <div class="row">
                  <make-priceformat :price="el.price" :subscriptSize="18" :firstSize="18" :secondSize="18">
                  </make-priceformat>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      floorGoodList: [], //楼层数据
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * @description 获取楼层
     * @return void
     */
    getFloorGoodsList() {
      this.$api.floorGoodsListApi().then((res) => {
        this.floorGoodList = this.handleFloorList(res.data);
        console.log(this.floorGoodList)
      });
    },
    handleFloorList(data) {
      return data.map(el => {
        el.select = 0;
        if (el.sons && el.sons.length) {
          this.handleFloorList(el.sons)
        }
        return el
      });
    }
  },
  created() {
    this.getFloorGoodsList();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
// 产品模块
.module-list {
  .module-item {
    margin-bottom: 45px;

    &:nth-child(2n) {
      .item-pro {
        .pro-l {
          flex-shrink: 0;
          //background: linear-gradient(#7cccee, #7cccee);
        }
      }
    }

    .item-title {
      .name {
        font-size: 26px;
      }

      .link-list {
        .link-item {
          &::after {
            content: "";
            width: 1px;
            height: 10px;
            background-color: #e3e3e3;
            margin: 0 10px;
            display: inline-block;
          }

          &:last-child::after {
            display: none;
          }
        }
      }
    }

    .item-pro {
      align-items: flex-start;

      .pro-l {
        flex-shrink: 0;
        width: 239px;
        height: 632px;
        padding: 45px 24px 30px;
        align-items: flex-start;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 12px;

        .pro-img {
          width: 191px;
          height: 182px;
        }

        .pro-tittle {
          &::after {
            content: "";
            width: 26px;
            height: 3px;
            background-color: white;
            display: block;
            margin-top: 15px;
          }
        }

        .pro-intro {
          line-height: 2;
        }
      }

      .pro-r {
        align-items: flex-start;
        flex: 1;

        .pro-item {
          width: 235px;
          height: 315px;
          background-color: $-color-white;
          margin-right: 3px;
          margin-top: 2px;

          //padding-top: 17px;
          &:nth-child(4n) {
            margin-right: 0;
          }

          &:nth-child(-n + 4) {
            margin-top: 0;
          }

          .item-img {
            width: 100%;
            height: 235px;
            // height: auto;
            margin-bottom: 25px;
          }

          .item-text {
            padding: 0 17px;
          }

          &:hover {
            .item-text {
              .text-title {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}
</style>